var media = document.getElementById("myHomeVideo");
var btn = document.getElementById("playBtn");
var text = document.getElementById("play-text");
btn.addEventListener('click', () => {
    if (media.paused) {
        media.play();
        text.style.visibility = "hidden";
        btn.innerHTML = 'PAUSE'
    } else {
        media.pause();
        text.style.visibility = "visible";
        btn.innerHTML = 'PLAY'
    }
})